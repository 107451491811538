import { useEffect, useState } from "react";
import Chat from "./components/Chat";
import "./App.css";
import 'react-loading-skeleton/dist/skeleton.css';

function App() {
  const [isKeyAvailable, setIsKeyAvailable] = useState(true);
  const [brainId, setBrainId] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [chatImage, setChatImage] = useState({ src: '', width: 140, height: 140 });
  const [userId, setUserId] = useState('');
  const [notificationMessage, setNotificationMessage] = useState({ title: '', body: '', delay: '' });

  useEffect(() => {
    // api-key
    const scriptElementApiKey = document.querySelector("script[data-curatu-api-key]");
    // User id
    const scriptElementUserId = document.querySelector("script[data-curatu-user-id]");
    // Brain Id
    const scriptElementBrainId = document.querySelector("script[data-curatu-knowledge-base]");
    // Image
    const scriptElementImage = document.querySelector("script[data-curatu-image]");
    // Image Width
    const scriptElementImageWidth = document.querySelector("script[data-curatu-image-width]");
    // Image Height
    const scriptElementImageHeight = document.querySelector("script[data-curatu-image-height]");
    //Notification Message Title
    const scriptElementNotificationMessageTitle = document.querySelector("script[data-curatu-notification-message-title]");
    //Notification Message body
    const scriptElementNotificationMessageBody = document.querySelector("script[data-curatu-notification-message-body]");
    //Notification Message delay
    const scriptElementNotificationMessageDelay = document.querySelector("script[data-curatu-notification-message-delay]");


    // To Do: Remove hardcoded API key from here
    // To Do: Remove hardcoded Brain Id from here
    const apiKey = scriptElementApiKey?.getAttribute("data-curatu-api-key")
      || 'Ej9QisIIkmJpc_IoXnsb6Rr_m9SBPQiutHpUuFcDqr4';
    const brainId = scriptElementBrainId?.getAttribute("data-curatu-knowledge-base")
      || 'ca71ebe8-c2c1-42ef-a92b-b4a36ddc2967';
    const chatbotImage = scriptElementImage?.getAttribute("data-curatu-image")
      || 'https://static.curatu.ai/assets/chat-icon.png';
    const currentUserId = scriptElementUserId?.getAttribute("data-curatu-user-id")
      || '4a81c07d-76e5-4060-a0d2-0e0e2f4ac84d';
    const chatbotImageWidth = scriptElementImageWidth?.getAttribute("data-curatu-image-width").replace(/\D/g, '')
      || 140;
    const chatbotImageHeight = scriptElementImageHeight?.getAttribute("data-curatu-image-height").replace(/\D/g, '')
      || 140;
    const notificationMessageTitle = scriptElementNotificationMessageTitle?.getAttribute("data-curatu-notification-message-title")
      || '';
    const notificationMessageBody = scriptElementNotificationMessageBody?.getAttribute("data-curatu-notification-message-body")
      || '';
    const notificationMessageDelay = scriptElementNotificationMessageDelay?.getAttribute("data-curatu-notification-message-delay")
      || 15000;
    // For Local development
    // const apiKey = scriptElementApiKey?.getAttribute("data-curatu-api-key")
    //   || 'mMJ1b3b_A84XBDFBQ57kr9XPnrmECk9KRsgjkC4s-wU';
    // const brainId = scriptElementBrainId?.getAttribute("data-curatu-knowledge-base")
    //   || '95e1e4d7-b5cf-4795-8d9a-7f9d12ecce7b';
    // const chatbotImage = scriptElementImage?.getAttribute("data-curatu-image")
    //   || 'https://static.curatu.ai/assets/chat-icon.png';
    // const currentUserId = scriptElementUserId?.getAttribute("data-curatu-user-id")
    //   || '';
    setBrainId(brainId);
    setApiKey(apiKey);
    setChatImage({ src: chatbotImage, width: chatbotImageWidth, height: chatbotImageHeight });
    setNotificationMessage({title: notificationMessageTitle, body: notificationMessageBody, delay: notificationMessageDelay})
    setUserId(currentUserId);

    setIsKeyAvailable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isKeyAvailable && (
        <div className="App">
          <Chat brainId={brainId} apiKey={apiKey} chatImage={chatImage} userId={userId} notificationMessage={notificationMessage}/>
        </div>
      )}
    </>
  );
}

export default App;
