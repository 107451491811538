import React, { forwardRef, useMemo } from "react";
import DOMPurify from "dompurify";
import Button from "../Button";
import Skeleton from "react-loading-skeleton";

export const TermsAndConditions = forwardRef(
  (
    {
      isMaximizeChatSize,
      onTermsAndConditionsChange,
      termsAndConditionsCopy,
      brandPrimaryColor,
      isBrandLoading,
      isLandscape,
    },
    ref
  ) => {
    // Sanitize the termsAndConditionsCopy using DOMPurify
    const sanitizedContent = useMemo(() => {
      return { __html: DOMPurify.sanitize(termsAndConditionsCopy) };
    }, [termsAndConditionsCopy]);

    return (
      <>
        {isBrandLoading ? (
          <div>
            <Skeleton count={1} height={32} className="curai-mb-4" />
            <Skeleton count={1} height={isLandscape ? 120 : 350} className="curai-mb-4" />
            <div className="curai-flex curai-justify-end">
              <Skeleton count={1} height={45} width={107} />
            </div>
          </div>
        ) : (
          <div
            className={`curai-flex curai-flex-col curai-items-end curai-justify-between curai-h-full curai-w-full curai-text-left ${
              isLandscape ? "curai-h-[240px]" : ""
            }`}
          >
            <div className="curai-text-2xl curai-font-bold curai-mb-1 curai-w-full curai-text-black">
              Terms and conditions.
            </div>
            <div
              className={`${
                isMaximizeChatSize
                  ? `chat-history ${isLandscape ? "curai-h-[150px]" : "curai-h-[375px]"} curai-overflow-scroll curai-w-full`
                  : "chat-history curai-h-full curai-overflow-scroll curai-w-full"
              }`}
            >
              {/* Use dangerouslySetInnerHTML to render the sanitized HTML */}
              <div
                className="curai-text-black"
                dangerouslySetInnerHTML={sanitizedContent}
              ></div>
            </div>
            <div>
              <Button
                id="curatu-agree-t-c"
                variant="primary"
                onClick={() => onTermsAndConditionsChange(true)}
                color={brandPrimaryColor}
                ref={ref}
              >
                I Agree
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
);
