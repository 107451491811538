// handleStream.js (Utility Function)
export const handleStream = async (reader, updateStreamingHistoryCallback) => {
    const decoder = new TextDecoder("utf-8");
  
    const handleStreamRecursively = async () => {
      const { done, value } = await reader.read();
  
      if (done) {
        return;
      }
  
      const dataStrings = decoder
        .decode(value)
        .trim()
        .split("data: ")
        .filter(Boolean);
  
      dataStrings.forEach((data) => {
        try {
          const parsedData = JSON.parse(data);
          updateStreamingHistoryCallback(parsedData);
        } catch (error) {
          console.error("Error parsing data", error);
        }
      });
  
      await handleStreamRecursively();
    };
  
    await handleStreamRecursively();
  };
  