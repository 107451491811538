/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { AgentBubble } from "./AgentBubble";
import { EndChat } from "../EndChat";
import { BotBubble } from "./BotBubble";
import { ThreeDots } from "react-loading-icons";
import "./chat.css";
import { handleStream } from "../../utils/handleStream.js";
import Send from "../Icons/Send";
import Cancel from "../Icons/Cancel";
import Email from "../Icons/Email.jsx";
import Minimize2 from "../Icons/Minimize-2.jsx";
import Maximize2 from "../Icons/Maximize-2.jsx";
import ChevronDown from "../Icons/ChevronDown";
import Transcript from "../Transcript";
import PeopleIcon from "../Icons/PeopleIcon";
import { TermsAndConditions } from "../TermsAndConditions";
import ReactMarkdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import { LinkRenderer } from "../LinkRenderer/index.js";
import { useBrandingApi } from "../../api/branding/useBrandingApi.js";
import { chatExpiryTime } from "../../lib/utils.js";

const hoverNoneCss = "hover:curai-bg-white";

export const ChatContainer = ({
  brainId,
  apiKey,
  userId,
  isChatWindowOpen,
  handleIsChatWindowOpen,
  setIsMaximizeChatSize,
  isMaximizeChatSize,
  handleLandscap,
}) => {
  const [chatValue, setChatValue] = useState("");
  const [chatCreate, setChatCreate] = useState("");
  const [isChatCreate, setIsChatCreate] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSessionHistory, setChatSessionHistory] = useState([]);
  const [isChatIdExist, setIsChatIdExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAssistantChatMessage, setCurrentAssistantChatMessage] =
    useState("");
  const [currentUserChatMessage, setCurrentUserChatMessage] = useState("");
  const [isTranscript, setIsTranscript] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const [hasChatEnded, setHasChatEnded] = useState(false);
  const [parentContainerHeight, setParentContainerHeight] = useState("520px");
  const [childContainerHeight, setchildContainerHeight] = useState("390px");
  const [isStreamComplete, setIsStreamComplete] = useState(false);
  const [sessionChatHistory, setSessionChatHistory] = useState([]);
  const [isLandscape, setIsLandscape] = useState(false);

  // Call useBrandingApi hook
  const { branding, isBrandLoading } = useBrandingApi(userId, apiKey, brainId);

  const { message, tnc, colorOne, colorTwo, title, logoUrl } = branding || {};
  const firstFocusableRef = useRef(null);
  const lastFocusableRef = useRef(null);
  const sendMessageContainerRef = useRef(null);

  useEffect(() => {
    setChatHistory(chatSessionHistory);
  }, [chatSessionHistory]);

  useEffect(() => {
    if (localStorage.getItem("cid")) {
      const cid = localStorage.getItem("cid");
      setAcceptTermsAndConditions(true);
      setIsChatIdExist(true);
      setChatCreate(cid);
    }

    if (JSON.parse(localStorage.getItem("chatMessage"))) {
      const message = JSON.parse(localStorage.getItem("chatMessage"));
      setChatSessionHistory(message);
      // setChatSessionHistory(message);
      setSessionChatHistory(message);
    }
  }, []);

  useEffect(() => {
    handleLandscap(isLandscape);
  }, [isLandscape]);

  // useEffect(() => {
  //   if (!isLoading && isStreamComplete && currentAssistantChatMessage !== "") {
  //     console.log("Final Assistant Message:", currentAssistantChatMessage);
  //   }
  // }, [isLoading, isStreamComplete]);

  useEffect(() => {
    if (
      chatSessionHistory.length !== 0 &&
      !isLoading &&
      isStreamComplete &&
      currentAssistantChatMessage !== ""
    ) {
      setChatHistory(chatSessionHistory);
      setSessionChatHistory((pre) => [
        ...pre,
        {
          assistant: currentAssistantChatMessage,
          user_message: currentUserChatMessage,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSessionHistory, isLoading, isStreamComplete]);

  useEffect(() => {
    if (sessionChatHistory.length !== 0) {
      localStorage.setItem("chatMessage", JSON.stringify(sessionChatHistory));
    }
  }, [sessionChatHistory]);

  // For chat auto scroll ref
  const chatHistoryRef = useRef(null);

  const resetChatSession = () => {
    handleIsChatWindowOpen(false);
    setChatValue("");
    setChatCreate("");
    setChatHistory([]);
    setIsChatIdExist(false);
    setCurrentAssistantChatMessage("");
    setCurrentUserChatMessage("");
    setIsTranscript(false);
    setAcceptTermsAndConditions(false);
    setIsMaximizeChatSize(true);
  };

  const handleTextbox = (event) => {
    setChatValue(event.target.value);
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", apiKey);

  const getAnswerRequestOptionsRaw = JSON.stringify({
    question: chatValue,
  });

  const getAnswerRequestOptions = {
    method: "POST",
    headers: myHeaders,
    body: getAnswerRequestOptionsRaw,
    redirect: "follow",
  };

  const handleChatToggle = () => {
    handleIsChatWindowOpen((prev) => !prev);
  };

  const handleChatClose = () => {
    setHasChatEnded(true);
  };

  const handleStreamApi = async (
    chatCreate,
    brainId,
    getAnswerRequestOptions
  ) => {
    setIsLoading(true);
    setIsStreamComplete(false);
    try {
      if (chatCreate !== "") {
        //Set focus to parent div of textarea since the textarea is disabled on message send
        //For accessibility
        sendMessageContainerRef?.current.focus();
        // Get the answer
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/chat-bot/${chatCreate}/question/stream/${brainId}`,
          getAnswerRequestOptions
        );
        const reader = response.body.getReader();
        await handleStream(reader, (data) => {
          const tokens = data.assistant; // Split the response into tokens
          setCurrentAssistantChatMessage((prev) => prev.concat(tokens));
          setCurrentUserChatMessage(chatValue);
        });
        chatExpiryTime();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setChatValue("");
      setIsLoading(false);
      setIsStreamComplete(true); // Indicate the stream is complete
    }
  };

  const handleChatCreate = async () => {
    setIsLoading(true);

    setChatSessionHistory((prev) => [
      ...prev,
      {
        user_message: currentUserChatMessage,
        assistant: currentAssistantChatMessage,
      },
    ]);

    setCurrentAssistantChatMessage("");
    setCurrentUserChatMessage("");

    try {
      if (!isChatIdExist && chatValue !== "") {
        //Set focus to parent div of textarea since the textarea is disabled on message send
        //For accessibility
        sendMessageContainerRef?.current?.focus();
        const requestOptionsRaw = JSON.stringify({
          name: chatValue,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: requestOptionsRaw,
          redirect: "follow",
        };

        // Get the Chat Id
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/chat-bot/${userId}`,
          requestOptions
        );
        const result = await response.json();
        setChatCreate(result.cid);
        setIsChatIdExist(true);
        setIsChatCreate(true);
        localStorage.setItem("cid", result.cid);
      } else {
        //Set focus to parent div of textarea since the textarea is disabled on message send
        //For accessibility
        sendMessageContainerRef?.current.focus();
        // To do: refactor below API call try to use handleStreamApi with
        // some modification to isolate fetch call and then another function
        // with try catch block
        // Get the answer
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/chat-bot/${chatCreate}/question/stream/${brainId}`,
          getAnswerRequestOptions
        );
        const reader = response.body.getReader();
        await handleStream(reader, (data) => {
          const tokens = data.assistant; // Split the response into tokens
          setCurrentAssistantChatMessage((prev) => prev.concat(tokens));
          setCurrentUserChatMessage(chatValue);
        });
        setChatValue("");
        chatExpiryTime();
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  // // For first question following useEffect gets executed
  useEffect(() => {
    handleStreamApi(chatCreate, brainId, getAnswerRequestOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatCreate]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //event.preventDefault();
      // Perform the action you want
      handleChatCreate();
    }
  };

  const handleTermsAndConditions = () => {
    setAcceptTermsAndConditions(true);
  };

  const handleEndChat = (value) => {
    setHasChatEnded(false);
    setIsTranscript(false);
    if (value) {
      localStorage.clear();
    }
    if (value) {
      resetChatSession();
    }
  };

  // Close chat when escape is pressed
  const handleEscapeKeyPress = (event) => {
    if (event.key === "Escape") {
      handleIsChatWindowOpen(false);
    }
  };

  // Function to trap focus within the chat window
  const trapFocus = (event) => {
    if (event.key === "Tab") {
      if (event.shiftKey) {
        /* shift + tab */ if (
          document.activeElement === firstFocusableRef.current
        ) {
          lastFocusableRef.current.focus();
          event.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableRef.current) {
          firstFocusableRef.current.focus();
          event.preventDefault();
        }
      }
    }
  };

  // For setting focus
  useEffect(() => {
    if (
      (isChatWindowOpen || !acceptTermsAndConditions) &&
      firstFocusableRef.current
    ) {
      firstFocusableRef.current.focus();
      window.addEventListener("keydown", handleEscapeKeyPress);
    }
    if (isChatWindowOpen) {
      window.addEventListener("keydown", trapFocus);
    }
    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
      window.removeEventListener("keydown", trapFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatWindowOpen, acceptTermsAndConditions]);

  // For going back to chat from transcript
  const handleBackToChat = () => {
    setIsTranscript(false);
    setHasChatEnded(false);
  };

  // For chat auto scroll
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory, currentUserChatMessage, currentAssistantChatMessage]);

  const handleTranscriptShowHide = () => {
    setIsTranscript((prev) => !prev);
    setHasChatEnded(false);
  };

  //For landscape view chatbot height
  useEffect(() => {
    function handleResize() {
      // Check if the viewport height is less than 520px
      if (window.innerHeight < 585) {
        // Set the div height to match the viewport height
        setIsLandscape(true);
        setParentContainerHeight(`${window.innerHeight - 100}px`);
        setchildContainerHeight(`${window.innerHeight - 215}px`);
      } else {
        setIsLandscape(false);

        // Otherwise, reset it back to the default
        setParentContainerHeight("520px");
        setchildContainerHeight("390px");
      }
    }

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Call handleResize immediately in case the initial viewport height is less than 520px
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Prevent scroll on mobile devices when the chat window is open.
  useEffect(() => {
    if (isChatWindowOpen && window.innerWidth < 410) {
      document.body.style.overflow = isChatWindowOpen ? "hidden" : "";
    }

    // Cleanup function to reset the style when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [isChatWindowOpen]);

  const checkExpiryOfChat = () => {
    const currentHour = Date.now();
    if (
      currentHour > localStorage.getItem("chatMessageExpiry") &&
      localStorage.getItem("chatMessageExpiry") !== null
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const isValidTime = checkExpiryOfChat();
    handleEndChat(isValidTime);
  }, []);

  return (
    <>
      <div
        className={`${
          isMaximizeChatSize
            ? "curai-absolute curai-rounded-lg curai-z-[1001] curai-h-[65px] curai-w-full"
            : "curai-w-full xs:curai-w-3/4"
        }`}
        ref={firstFocusableRef}
        tabIndex={0}
        aria-label="Chat Widget Window"
        role="dialog"
      >
        <div
          className={`${
            isMaximizeChatSize
              ? "curai-bg-primary curai-text-white curai-w-full xs:curai-w-400 curai-h-[65px] curai-absolute curai-flex curai-justify-between curai-rounded-t-lg curai-z-[1001] curai-max-w-full"
              : "curai-bg-primary curai-justify-between curai-text-white curai-flex curai-rounded-t-lg curai-z-[1001] curai-w-full curai-h-[65px]"
          }`}
          style={{ backgroundColor: colorOne }}
          aria-label="Chat Widget Navigation Region"
          role="region"
        >
          <div
            className={`${
              isMaximizeChatSize
                ? "curai-flex curai-items-center "
                : "curai-flex curai-items-center curai-w-full"
            }`}
          >
            {isBrandLoading ? (
              <div
                className={`${
                  isMaximizeChatSize
                    ? "curai-flex curai-items-center"
                    : "curai-flex curai-items-center curai-w-full"
                }`}
              >
                <Skeleton
                  count={1}
                  height={33}
                  width={33}
                  className="!curai-rounded-full curai-ml-5"
                />
                <Skeleton
                  count={1}
                  height={20}
                  width={120}
                  className="curai-ml-5"
                />
              </div>
            ) : (
              <>
                {logoUrl ? (
                  <img
                    src={`${logoUrl}`}
                    alt=""
                    className="curai-h-[33px] curai-ml-5"
                    aria-hidden={true}
                  />
                ) : (
                  <div className="curai-relative curai-flex curai-items-center curai-w-10 curai-h-10 curai-overflow-hidden curai-bg-[white] curai-rounded-full dark:curai-bg-gray-600 curai-ml-5">
                    <span className="curai-font-medium curai-text-gray-600 dark:curai-text-gray-300 curai-text-white curai-my-0 curai-mx-auto">
                      <img
                        src={`${process.env.REACT_APP_EMBED_URL}/assets/header-chat-icon.svg`}
                        alt=""
                        className="curai-h-5 curai-w-5"
                      />
                    </span>
                  </div>
                )}
              </>
            )}
            {title && <p className="curai-ml-5 curai-text-lg">{title}</p>}
          </div>
          <div className="curai-relative curai-flex curai-justify-end curai-mr-5 curai-gap-4 curai-items-center">
            <button
              id="curatu-send-transcript"
              title="Email Transcript"
              className="hover:curai-ring-1 hover:curai-ring-white focus:curai-box-border curai-border-none curai-text-white curai-bg-transparent curai-p-0"
              onClick={() => handleTranscriptShowHide()}
              disabled={!acceptTermsAndConditions}
            >
              <Email />
            </button>

            {isMaximizeChatSize ? (
              <button
                id="curatu-maximize-chat-window"
                title="Maximize Chat Window"
                className="hover:curai-ring-1 curai-hidden xs:curai-block max-h-400:curai-hidden hover:curai-ring-white focus:curai-box-border curai-border-none curai-text-white curai-bg-transparent curai-p-0"
                onClick={() => setIsMaximizeChatSize(false)}
              >
                <Maximize2 />
              </button>
            ) : (
              <button
                id="curatu-minimize-chat-window"
                title="Minimize Chat Window"
                className="hover:curai-ring-1 curai-hidden xs:curai-block max-h-400:curai-hidden hover:curai-ring-white focus:curai-box-border curai-border-none curai-text-white curai-bg-transparent curai-p-0"
                onClick={() => setIsMaximizeChatSize(true)}
              >
                <Minimize2 />
              </button>
            )}

            <button
              onClick={handleChatToggle}
              title="Collapse Chat Window"
              id="curatu-minimize-chat"
              className="hover:curai-ring-1 hover:curai-ring-white focus:curai-box-border curai-border-none curai-text-white curai-bg-transparent curai-p-0"
            >
              <ChevronDown />
            </button>

            <button
              onClick={handleChatClose}
              title="Close"
              id="curatu-close-chat"
              className="hover:curai-ring-1 hover:curai-ring-white focus:curai-box-border curai-border-none curai-text-white curai-bg-transparent curai-p-0"
            >
              <Cancel />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          isMaximizeChatSize
            ? "curai-h-[520px] curai-rounded-b-lg curai-shadow-xl curai-p-5 curai-flex curai-flex-col curai-overflow-hidden curai-mt-[61px] curai-h-inherit curai-w-[400px] curai-relative curai-max-w-full curai-box-border curai-container-land curai-bg-white"
            : "curai-h-[90%] curai-w-full xs:curai-w-3/4 curai-p-5 curai-rounded-b-lg curai-shadow-xl curai-box-border curai-bg-white curai-overflow-auto"
        }`}
        aria-label="Chat Widget Main Region"
        role="region"
        style={
          isMaximizeChatSize && isLandscape
            ? { height: parentContainerHeight }
            : isMaximizeChatSize
            ? { height: "520px" }
            : { height: "90%" }
        }
      >
        {hasChatEnded ? (
          <EndChat
            onEndChat={handleEndChat}
            brandPrimaryColor={colorOne}
            ref={lastFocusableRef}
            isMaximizeChatSize={isMaximizeChatSize}
            isLandscape={isLandscape}
          />
        ) : (
          <>
            {!acceptTermsAndConditions ? (
              <TermsAndConditions
                onTermsAndConditionsChange={handleTermsAndConditions}
                termsAndConditionsCopy={tnc}
                brandPrimaryColor={colorOne}
                ref={lastFocusableRef}
                isBrandLoading={isBrandLoading}
                isMaximizeChatSize={isMaximizeChatSize}
                isLandscape={isLandscape}
              />
            ) : (
              <>
                {isTranscript || hasChatEnded ? (
                  <Transcript
                    chatId={chatCreate}
                    apiKey={apiKey}
                    brandPrimaryColor={colorOne}
                    ref={lastFocusableRef}
                    onBackToChat={handleBackToChat}
                    isMaximizeChatSize={isMaximizeChatSize}
                    isLandscape={isLandscape}
                    userId={userId}
                    brainId={brainId}
                  />
                ) : (
                  <div
                    className={`${
                      isMaximizeChatSize
                        ? "curai-pt-3 curai-h-[85%]"
                        : "curai-w-full curai-h-full curai-pt-3 curai-flex curai-flex-col curai-justify-between curai-box-border"
                    }`}
                  >
                    <div
                      className={`${
                        isMaximizeChatSize
                          ? "chat-history curai-pb-[15px] curai-overflow-scroll curai-h-[44vh]"
                          : "chat-history curai-h-full curai-pb-[15px] curai-overflow-scroll"
                      }`}
                      tabIndex={0}
                      ref={chatHistoryRef}
                      style={
                        isMaximizeChatSize && isLandscape
                          ? { height: childContainerHeight }
                          : { height: "100%" }
                      }
                    >
                      <div
                        className={`${
                          isMaximizeChatSize
                            ? "curai-pb-1 curai-pt-1 curai-w-[310px] curai-overflow-hidden curai-text-left"
                            : "curai-pb-1 curai-pt-1 curai-w-5/6 sm:curai-w-9/12 curai-overflow-hidden curai-text-left"
                        }`}
                      >
                        <div className="curai-flex">
                          <span aria-hidden={true}>
                            <PeopleIcon
                              className="curai-mr-1"
                              color={colorOne}
                            />
                          </span>
                          <div
                            className={`${
                              isMaximizeChatSize
                                ? " isMaximizeChatSizecurai-break-words curai-p-1 curai-rounded-t-lg curai-rounded-br-lg curai-rounded- curai-w-full curai-bg-[#f4f4f4] curai-mb-1 curai-text-base curai-text-black curai-leading-[22px]"
                                : "isMaximizeChatSizecurai-break-words curai-p-1 curai-rounded-t-lg curai-rounded-br-lg curai-rounded- curai-bg-[#f4f4f4] curai-mb-1 curai-text-base curai-text-black curai-leading-[22px]"
                            }
                          `}
                          >
                            {message ? message : "Hi there!"}
                          </div>
                        </div>
                        <div className="curai-text-xs curai-ml-8 curai-text-black">
                          Bot
                        </div>
                      </div>
                      {chatHistory.length > 0 &&
                        chatHistory?.map((item, index) => {
                          const { user_message, assistant } = item;
                          return (
                            user_message &&
                            assistant && (
                              <div key={`chat-${index}`}>
                                <AgentBubble
                                  message={user_message}
                                  brandColor={colorOne}
                                  isMaximizeChatSize={isMaximizeChatSize}
                                />
                                <BotBubble
                                  message={
                                    <ReactMarkdown
                                      components={{ a: LinkRenderer }}
                                    >
                                      {assistant}
                                    </ReactMarkdown>
                                  }
                                  brandColor={colorTwo}
                                  currentMessage={false}
                                  brandColorPrimary={colorOne}
                                  isMaximizeChatSize={isMaximizeChatSize}
                                />
                              </div>
                            )
                          );
                        })}

                      {currentUserChatMessage && (
                        <AgentBubble
                          message={currentUserChatMessage}
                          brandColor={colorOne}
                          isMaximizeChatSize={isMaximizeChatSize}
                        />
                      )}
                      {currentAssistantChatMessage && (
                        <BotBubble
                          loading={isLoading}
                          message={
                            <ReactMarkdown components={{ a: LinkRenderer }}>
                              {currentAssistantChatMessage}
                            </ReactMarkdown>
                          }
                          brandColor={colorTwo}
                          currentMessage={true}
                          brandColorPrimary={colorOne}
                          isMaximizeChatSize={isMaximizeChatSize}
                        />
                      )}
                    </div>
                    <div
                      className={`${
                        isMaximizeChatSize
                          ? " curai-sticky curai-left-[15px] curai-right-[15px] curai-h-[70px] -curai-bottom-[25px]"
                          : "curai-h-[92px] curai-sticky curai-left-[15px] curai-right-[15px] curai-bottom-[25px] curai-w-full"
                      }`}
                      tabIndex={-1}
                      ref={sendMessageContainerRef}
                    >
                      <div
                        className={`${
                          isMaximizeChatSize
                            ? ""
                            : "curai-h-[92px] curai-sticky curai-left-[15px] curai-right-[15px] curai-bottom-[25px] curai-w-full curai-box-border"
                        }`}
                      >
                        <textarea
                          aria-label="Ask any question"
                          value={chatValue}
                          onChange={(event) => handleTextbox(event)}
                          className={`${
                            isMaximizeChatSize
                              ? "curai-w-full curai-p-1 curai-outline-none curai-rounded-lg curai-border-lightGrey curai-border curai-py-1 curai-pl-1 curai-pr-[65px] curai-text-black curai-h-[70px] curai-text-base chat-fonts"
                              : "curai-border-lightGrey curai-p-1 curai-text-black curai-text-base curai-w-full curai-h-full curai-pr-[65px] curai-border curai-outline-none curai-rounded-lg curai-box-border chat-fonts"
                          }`}
                          placeholder="Ask any question"
                          onKeyDown={(event) => handleKeyPress(event)}
                          ref={chatValue === "" ? lastFocusableRef : null}
                          disabled={isLoading}
                        />
                        <div className="">
                          <button
                            id="curatu-send-message"
                            type="submit"
                            title="Send Message"
                            className={`curai-absolute curai-bottom-[22px] curai-right-[10px] curai-top-[20px] disabled:curai-opacity-50 curai-border-none curai-text-white curai-bg-transparent curai-p-0
                              ${
                                isLoading || chatValue === ""
                                  ? ""
                                  : "hover:curai-ring-1 hover:curai-ring-black"
                              } ${isLoading ? hoverNoneCss : ""}`}
                            onClick={() => handleChatCreate()}
                            disabled={isLoading || chatValue === ""}
                            ref={chatValue === "" ? null : lastFocusableRef}
                          >
                            {isLoading ? (
                              <ThreeDots
                                fill={`${
                                  colorOne !== "" ? colorOne : "#1b2158"
                                }`}
                                fillOpacity="1"
                                width="52"
                                className="loading-spinner"
                                aria-live="assertive"
                                aria-label="We are processing your request."
                              />
                            ) : (
                              <Send color={colorOne} />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
