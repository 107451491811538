import { useEffect, useState } from "react";
import "./chat.css";
import { ChatContainer } from "./ChatContainer.jsx";
import Cancel from "../Icons/Cancel";

const Chat = ({ brainId, apiKey, chatImage, userId, notificationMessage }) => {
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [isChatNotificationVisible, setIsChatNotificationVisible] =
    useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isMaximizeChatSize, setIsMaximizeChatSize] = useState(true);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("cid")) {
      handleIsChatWindowOpen(true);
    }
  }, []);

  const renderButtonLabel = () => {
    if (chatImage.src !== "" && !isChatWindowOpen) {
      return (
        <img
          src={chatImage.src}
          className={`curai-rounded-md`}
          style={{
            width: `${chatImage.width}px`,
            height: `${chatImage.height}px`,
          }}
          alt="Chatbot"
        />
      );
    } else {
      return <span>Chatbot</span>;
    }
  };

  const handleChatToggle = () => {
    setIsChatWindowOpen((prev) => !prev);
  };

  const handleIsChatWindowOpen = (chatWindowOpen) => {
    setIsChatWindowOpen(chatWindowOpen);
  };

  useEffect(() => {
    if (isChatWindowOpen) {
      setIsChatNotificationVisible(false);
      clearTimeout(timeoutId);
    }
  }, [isChatWindowOpen, timeoutId]);

  useEffect(() => {
    if (
      notificationMessage?.title &&
      notificationMessage?.body &&
      notificationMessage?.delay &&
      !isChatWindowOpen
    ) {
      const timeoutId = setTimeout(() => {
        setIsChatNotificationVisible(true);
      }, parseInt(notificationMessage?.delay));
      setTimeoutId(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationMessage?.delay, isChatWindowOpen]);

  const handleLandscap = (val) => {
    setIsLandscape(val);
  };

  return (
    <div
      className={`${isMaximizeChatSize || !isChatWindowOpen
          ? "chat-container-curai curai-right-0 xs:curai-right-[10px] xs:curai-w-auto"
          : ` ${isLandscape ? "curai-h-[80vh]" : "curai-h-[90vh]"
          } curai-flex fixed curai-z-50 top-0 curai-justify-center curai-fixed curai-my-[32px] curai-flex-col curai-items-center curai-w-full curai-right-[1px] chat-fonts curai-height-landscape`
        } ${isChatNotificationVisible && !isChatWindowOpen
          ? "curai-flex curai-flex-col curai-items-end"
          : ""
        } ${!isMaximizeChatSize && isChatWindowOpen ? 'curai-top-0' : ''} chat-bot-wrapper-curai`}
    >
      {isChatWindowOpen && (
        <ChatContainer
          brainId={brainId}
          apiKey={apiKey}
          userId={userId}
          isChatWindowOpen={isChatWindowOpen}
          handleIsChatWindowOpen={handleIsChatWindowOpen}
          isMaximizeChatSize={isMaximizeChatSize}
          setIsMaximizeChatSize={setIsMaximizeChatSize}
          handleLandscap={handleLandscap}
        />
      )}

      {isChatNotificationVisible && (
        <div className="curai-bg-[#fefafa] curai-p-5 curai-rounded-md curai-relative curai-bubble-shadow curai-max-w-[290px] curai-bubble-container curai-border curai-border-solid curai-border-slate-400 curai-text-left">
          <button
            className="curai-bg-[grey] curai-border-none curai-absolute curai-right-3 -curai-top-3 curai-rounded-full curai-h-7 curai-w-7 curai-flex curai-justify-center curai-items-center curai-bubble-close-button curai-text-white"
            aria-hidden="true"
            onClick={() => setIsChatNotificationVisible(false)}
          >
            <Cancel styleClass="curai-w-4 curai-h-4" />
          </button>
          <p
            className="curai-mt-0 curai-text-[#4d5056] curai-text-base curai-mb-3 curai-font-bold"
            aria-live="polite"
            id="curatu-notification-title"
          >
            {notificationMessage?.title}
          </p>
          <p className="curai-mb-0 curai-text-[#020c27] curai-text-sm">
            {notificationMessage?.body}
          </p>
          <button
            className="curai-sr-only curai-skip-link"
            onClick={() => setIsChatNotificationVisible(false)}
          >
            Close<span className="curai-sr-only"> notification section</span>
          </button>
        </div>
      )}

      <button
        className={`curai-mt-3 curai-w-auto curai-h-auto curai-bg-none curai-border-none curai-text-white curai-bg-transparent curai-p-0 ${isChatWindowOpen ? "curai-hidden" : ""
          }`}
        onClick={handleChatToggle}
        id="curatu-start-chat-session"
      >
        {renderButtonLabel()}
      </button>
    </div>
  );
};

export default Chat;
