import React, { forwardRef } from "react";
import Button from "../Button";
import { useState } from "react";
import { ThreeDots } from "react-loading-icons";
import Check from "../Icons/Check";

const Transcript = forwardRef(
  (
    { chatId, userId, brainId, apiKey,isLandscape , brandPrimaryColor, onBackToChat, isMaximizeChatSize },
    ref
  ) => {
    const [emailid, setEmailid] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", apiKey);

    const getAnswerRequestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const handleSendEmail = async (chatCreate, requestOptions) => {
      try {
        if (chatCreate !== "") {
          setIsLoading(true);
          // Get the answer
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/send-chat-email/${chatCreate}/${userId}/${brainId}/${emailid}`,
            requestOptions
          );

          setIsSuccessful(true);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    const handleTranscriptSend = () => {
      handleSendEmail(chatId, getAnswerRequestOptions);
    };

    return (
      <div
        className={`chat-fonts curai-flex curai-flex-col ${
          isLandscape && isMaximizeChatSize ? "curai-px-2" : "curai-p-2"
        } curai-mailPad-land curai-h-full curai-box-border curai-overflow-y-auto`}
      >
        {isSuccessful && emailid ? (
          <div className="curai-flex curai-justify-center curai-items-center curai-flex-col">
            <span aria-hidden={true}>
              <Check color={brandPrimaryColor} />
            </span>
            <p
              className="curai-text-base curai-mt-7 curai-mb-7 curai-text-black curai-font-bold curai-text-center"
              aria-live="polite"
            >
              Transcript has been sent to {emailid}.
            </p>
          </div>
        ) : (
          <>
            <p className="curai-mb-2 curai-font-bold curai-text-xl curai-text-left curai-text-black curai-mt-0">
              Do you wish to receive transcript of your conversation?{" "}
            </p>
            <p
              className={`curai-text-base curai-text-left curai-text-black curai-mail-land ${
                isLandscape ? "curai-my-2" : "curai-mb-7"
              } `}
            >
              Please enter your email address to receive your conversation
              history.
            </p>
            <div className="curai-flex curai-flex-col curai-w-full curai-justify-between curai-h-full ">
              <div className="curai-max-w-full curai-flex curai-flex-col">
                <label
                  htmlFor="email-09766543"
                  className="curai-text-slate-500 curai-text-base curai-font-normal curai-leading-tight curai-text-left curai-mb-[10px]"
                >
                  Email Address
                </label>
                <input
                  ref={emailid === "" ? ref : null}
                  type="text"
                  id="email-09766543"
                  className={` curai-bg-white curai-rounded-lg curai-px-4 curai-py-3 curai-border curai-border-solid curai-border-lightGrey ${
                    isMaximizeChatSize
                      ? "curai-h-[45px] curai-w-auto curai-font-placeholderGrey"
                      : "curai-h-[45px] curai-w-[92%] md:curai-w-1/2 curai-font-placeholderGrey curai-trans-land"
                  } curai-text-base curai-text-black`}
                  value={emailid}
                  onChange={(event) => setEmailid(event.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="curai-flex curai-justify-between curai-mt-2">
              <Button
                variant="tertiary"
                ref={ref}
                onClick={onBackToChat}
                id="curatu-transcript-go-back-chat"
              >
                Back to Chat
              </Button>
              <Button
                id="curatu-send-email"
                ref={emailid === "" ? null : ref}
                color={brandPrimaryColor}
                variant="primary"
                className={
                  isLoading || emailid === "" ? "hover:curai-no-underline" : ""
                }
                onClick={handleTranscriptSend}
                disabled={isLoading || emailid === ""}
              >
                {isLoading ? (
                  <ThreeDots
                    fill="#FFFFFF"
                    fillOpacity="1"
                    width="75"
                    className="loading-spinner"
                    aria-live="assertive"
                    aria-label="We are processing your request."
                  />
                ) : (
                  "Email me a copy"
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default Transcript;
