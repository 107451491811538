import React, { forwardRef } from "react";
import Button from "../Button";

export const EndChat = forwardRef(
  ({ onEndChat, brandPrimaryColor, isMaximizeChatSize, isLandscape }, ref) => {
    return (
      <div
        className={`${
          isMaximizeChatSize
            ? `curai-flex ${!isLandscape ? "curai-h-[480px]": "curai-h-[235px]"} curai-overflow-auto`
            : `curai-flex curai-justify-center curai-items-center curai-h-full curai-w-full`
        }`}
      >
        <div
          className={`${
            isMaximizeChatSize
              ? "curai-text-left"
              : "curai-text-left curai-flex-col curai-flex curai-justify-between curai-items-center curai-h-full curai-w-full"
          }`}
        >
          <div
            className={`chat-history ${
              isMaximizeChatSize
                ? `${
                    isLandscape ? "curai-h-[72%]" : "curai-h-[415px]"
                  } curai-endChat-land`
                : "curai-h-full curai-w-full"
            } curai-p-2 curai-overflow-scroll`}
          >
            <div className="curai-mb-2 curai-font-bold curai-text-xl curai-text-left curai-text-black">
              End chat?
            </div>
            <p className="curai-mb-5 curai-text-base curai-text-black">
              Please note, ending the chat will erase the chat history.
            </p>
            <p className="curai-mb-5 curai-text-base curai-text-black">
              To keep a copy, click "Email Icon" at the top before exiting.
            </p>
          </div>
          <div
            className={`curai-flex ${
              isMaximizeChatSize ? "]" : "curai-w-full"
            } curai-justify-between curai-mx-[14px] curai-my-[20px] curai-end-chat-land`}
          >
            <Button
              variant="tertiary"
              id="curatu-end-go-back-chat"
              className="curai-mr-3"
              color={brandPrimaryColor}
              onClick={() => onEndChat(false)}
              ref={ref} // This is the forwarded ref
            >
              Back to Chat
            </Button>
            <Button
              variant="primary"
              color={brandPrimaryColor}
              onClick={() => onEndChat(true)}
              id="curatu-confirm-end-chat"
              ref={ref} // This is the forwarded ref
            >
              End Chat
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
