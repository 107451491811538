export const LinkRenderer = (props) => {
  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(\+?\d{1,4}?[-.\s]?)?(\(?\d{1,4}?\)?[-.\s]?)?(\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})$/;
    return phoneRegex.test(phoneNumber);
  }

  const getHref = () => {
    if(isValidPhoneNumber(props.children)) {
      return `tel:${props.children}`
    }
    else {
      return props.href.replace(".html", "")
    }
  }

  return (
    <a href={getHref()} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}