import PeopleIcon from "../Icons/PeopleIcon";

export const BotBubble = ({
  message,
  brandColor,
  brandColorPrimary,
  loading,
  currentMessage,
  isMaximizeChatSize,
}) => {
  return (
    <>
      <div
        className={`${
          isMaximizeChatSize
            ? "curai-py-1 curai-w-full xs:curai-w-[320px] curai-overflow-hidden curai-text-left"
            : "curai-py-1 curai-w-5/6 sm:curai-w-9/12 curai-overflow-hidden curai-text-left"
        }`}
      >
        <div className="curai-flex">
          <div aria-hidden={true}>
            <PeopleIcon className="curai-mr-1" color={brandColorPrimary} />
          </div>
          <div
            className="curai-break-words curai-p-1 curai-rounded-t-lg curai-rounded-br-lg curai-rounded- curai-bg-[#f4f4f4] curai-mb-1 curai-text-base curai-text-black prose-curatu"
            aria-atomic="true"
            {...(!loading && currentMessage && { "aria-live": "polite" })}
            style={{ backgroundColor: brandColor }}
          >
            {message}
          </div>
        </div>
        <div className="curai-text-xs curai-ml-8 curai-text-black">Bot</div>
      </div>
    </>
  );
};
