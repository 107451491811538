export const AgentBubble = ({ message, brandColor, isMaximizeChatSize }) => {
  return (
    <div
      className={`${
        isMaximizeChatSize
          ? "curai-w-[270px] curai-overflow-hidden curai-float-right"
          : "curai-w-5/6 sm:curai-w-9/12 curai-overflow-hidden curai-float-right"
      }`}
    >
      <div
        className="curai-p-1 curai-bg-[#005290] curai-text-white curai-rounded-t-lg curai-rounded-bl-lg curai-float-right curai-text-base curai-text-left"
        style={{ backgroundColor: brandColor }}
      >
        <div className="curai-w-full">
          <p className="curai-m-0">{message}</p>
        </div>
      </div>
    </div>
  );
};
