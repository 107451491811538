/* eslint-disable */
import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import { cn } from "../../lib/utils.js";

const ButtonVariants = cva(
  "curai-px-8 curai-py-3 curai-text-sm disabled:curai-opacity-80 curai-text-center curai-font-medium curai-rounded-md focus:curai-ring curai-ring-primary/10 curai-outline-none curai-gap-2 curai-transition-opacity",
  {
    variants: {
      variant: {
        primary:
          "curai-cursor-pointer curai-py-2.5 curai-text-white curai-px-[30px] curai-bg-primary curai-rounded-lg curai-box-border curai-h-[45px] curai-justify-center hover:curai-underline focus:curai-underline focus:curai-ring-2 focus:curai-ring-primary curai-transition-colors curai-transition-underline curai-font-semibold curai-border-none disabled:!curai-opacity-70",
        tertiary:
          "curai-text-primary curai-relative curai-text-sm curai-leading-[20px] curai-font-semibold curai-text-left curai-bg-transparent hover:curai-underline focus:curai-underline focus:curai-ring-2 focus:curai-ring-primary curai-transition-colors curai-transition-underline !curai-px-0 !curai-py-0 curai-border-none disabled:!curai-opacity-70",
        secondary:
          "curai-cursor-pointer curai-py-2.5 curai-px-[30px] curai-bg-[transparent] curai-rounded-lg curai-box-border curai-h-[45px] curai-border-[1px] curai-border-solid curai-border-primary hover:curai-underline focus:curai-ring-2 focus:curai-ring-primary focus:curai-underline hover:curai-text-underline curai-transition-colors curai-transition-underline curai-font-semibold  curai-text-primary disabled:!curai-opacity-70",
        danger:
          "curai-border curai-border-red-500 hover:curai-bg-red-500 hover:curai-text-white curai-transition-colors",
      },
      brightness: {
        dim: "",
        default: "",
      },
    },
    defaultVariants: {
      variant: "primary",
      brightness: "default",
    },
  }
);

const Button = forwardRef(
  (
    {
      className,
      children,
      variant,
      brightness,
      color,
      isLoading,
      ...props
    },
    forwardedRef
  ) => {
    const primaryBaseStyle = color && variant === "primary" ? { backgroundColor: color } : {};
    const secondaryBaseStyle = color && variant === "secondary" ? { color: color, borderColor: color } : {};
    const styles = (variant) => {
      if (variant === "primary") {
        return primaryBaseStyle
      }
      else if (variant === "secondary") {
        return secondaryBaseStyle
      }
      else {
        return {}
      }
    }
    return (
      <button
        className={cn(ButtonVariants({ variant, brightness, className }))}
        disabled={isLoading}
        style={styles(variant)}
        {...props}
        ref={forwardedRef}
      >
        <span>{children} </span>{" "}
        {isLoading && 'Loading...'}
      </button>
    );
  }
);

Button.displayName = "Button";
export default Button;
