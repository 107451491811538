import { useEffect, useState } from 'react';
import axios from 'axios';

import { getBrandingData } from './branding';

export const useBrandingApi = (userId, apiKey, kbId) => {
    const [branding, setBranding] = useState(null);
    const [error, setError] = useState(null);
    const [isBrandLoading, setIsBrandLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const axiosInstance = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                }
            });
            try {
                setIsBrandLoading(true);
                const data = await getBrandingData(userId, kbId, axiosInstance);
                setBranding(data);
            } catch (err) {
                setError(err);
            }
            finally {
                setIsBrandLoading(false);
            }
        };

        if (userId && apiKey) {
            fetchData();
        }
    }, [userId, apiKey, kbId]); // Dependency array now includes userId

    return { branding, isBrandLoading, error };
};
